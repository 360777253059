<template>
  <div class="team-mgmt">
    <!-- INFO DIALOG -->
    <v-alert v-if="failed"
             prominent
             type="error"
             outlined
             text
             border="left"
             class="flex-center"
    >
      {{ $t('error_loading_retry') }}

      <div class="spacer" />

      <v-btn color="accent" outlined @click="initialLoad">
        {{ $t('actions.retry') }}
      </v-btn>
    </v-alert>

    <v-alert type="info"
             text
             :prominent="!user.organization"
             :border="!user.organization ? 'left' : undefined"
    >
      <template v-if="user.organization">
        <h3 v-text="orgName" />

        <div v-if="$root.isRootAccount"
             style="margin-top: 15px"
             v-html="$t('team.organization.info_root', { organization: $escapeHtml(orgName) })"
        />

        <template v-else>
          <div style="margin-top: 15px"
               v-html="$t('team.organization.info_org', {
                 organization: $escapeHtml(orgName), root_email: rootUserEmail, root_name: $escapeHtml(rootUserName) })"
          />

          <div style="margin-top: 5px" v-if="!loading && !failed">
            <span v-html="$t('team.organization.info_role', {
              roles: userRoles })"
            />
            <helptip position="bottom"
                     :width="250"
            >
              <span v-html="userRoleDescription" />
              <a @click="showRolesBrowser = true" v-text="$t('team.roles_browser_btn')" />.
            </helptip>
          </div>
        </template>
      </template>
      <div v-else class="flex-center">
        {{ $t('team.organization.none') }}
        <v-spacer />
        <v-btn color="primary"
               outlined
               @click="openNewOrgDialog"
        >
          {{ $t('team.organization.btn_new') }}
        </v-btn>
      </div>
    </v-alert>

    <!-- Global team settings -->
    <v-form
      v-if="hasEditPermission && !!user.organization"
      ref="organizationForm"
      v-model="organizationFormValid"
      @submit.prevent
      @keydown.enter="saveOrganization"
    >
      <v-card class="team-settings">
        <v-card-title>
          <div>
            <div class="title">
              {{ $t('team.settings.title') }}
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="col1">
            <v-text-field v-model="organization.name"
                          maxlength="20"
                          minlength="3"
                          :disabled="loading || savingOrganization"
                          @input="setOrganizationDirtyDebounced"
                          :rules="orgNameRules"
                          data-private
                          counter
                          outlined
                          :label="$t('team.settings.organization')"
            />
          </div>
          <fieldset class="default-settings">
            <legend>
              {{ $t('team.settings.default_title') }}
              <helptip :width="300" position="top">
                <span v-html="$t('team.settings.default_helptip')" />
              </helptip>
            </legend>
            <div>
              <!-- <v-checkbox v-model="organization.user_settings.opt_out_tracking"
                          :label="$t('profile.settings.opt_out_tracking')"
                          :disabled="loading || savingOrganization"
                          @change="setOrganizationDirtyDebounced"
              /> -->
              <div class="dummy-strings">
                <v-text-field v-for="k in 3"
                              :key="`dummy${k}`"
                              v-model="organization.user_settings.dummy_strings[k - 1]"
                              :disabled="loading || savingOrganization"
                              @input="setOrganizationDirtyDebounced"
                              maxlength="20"
                              data-private
                              counter
                              outlined
                              dense
                              :label="$t('profile.settings.dummy_string') + ` ${k}`"
                />
                <helptip :width="500" x-offset="-200px" position="top">
                  <span v-html="$t('profile.settings.dummy_string_helptip')" />
                  <a
                    target="_blank"
                    href="https://caplena.com/docs/knowledge-base/fgz27i31b0aij-credits"
                    v-text="$t('actions.more_information')"
                  />
                </helptip>
              </div>
            </div>
          </fieldset>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="primary"
            type="submit"
            :disabled="loading || failed || savingOrganization || !organizationDirty || !organizationFormValid"
            :loading="savingOrganization"
            @click.prevent.stop="saveOrganization"
          >
            {{ $t('team.settings.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <!-- USER TABLE -->
    <v-card v-if="!!user.organization" class="members">
      <v-card-title>
        <div>
          <div class="title">
            {{ $t('team.users.title') }}
          </div>
          <div class="grey--text">
            {{ $t('team.users.subtitle') }}
          </div>
        </div>
      </v-card-title>

      <v-data-table :headers="userTableHeaders"
                    :items="teamMembers"
                    :items-per-page="15"
                    :loading="loading"
                    class="team-table"
      >
        <template v-slot:item.name="{ item }">
          <div data-private>
            <div>{{ item.name }}</div>
            <a :href="`mailto: ${item.email}`" v-text="item.email" />
          </div>
        </template>

        <template v-slot:item.date_joined="{ item }">
          {{ item.date_joined | datetimeshort }}
        </template>

        <template v-slot:item.last_login="{ item }">
          <template v-if="!!item.last_login">
            {{ item.last_login | fromnow }}
          </template>
        </template>

        <template v-slot:item.roleDisplayed="{ item }">
          <div class="role-editor" v-if="hasEditPermission && item.id !== user.id">
            <v-select v-model="item.newRole"
                      dense
                      hide-details
                      single-line
                      solo
                      small
                      :disabled="savingRole"
                      :items="roleSelect"
                      :menu-props="{ 'contentClass': 'select-menu-with-helptips' }"
                      item-disabled="disabled"
            >
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.text" />
                </v-list-item-content>
                <helptip position="bottom"
                         :width="250"
                         :anchor-icon="item.disabled ? 'mdi-cancel' : undefined"
                >
                  <feature-unavailable v-if="!item.available" />
                  <span v-else-if="!item.settable"
                        v-html="$t('permission.no_permission', {
                          email: user.organization.root_user_email
                        } )"
                  />
                  <div v-else>
                    <span v-html="item.description" />
                    <a @click="showRolesBrowser = true" v-text="$t('team.roles_browser_btn')" />.
                  </div>
                </helptip>
              </template>
            </v-select>
            <v-btn v-if="item.currentRole !== item.newRole"
                   outlined
                   color="primary"
                   small
                   :loading="savingRole"
                   @click="saveUserRoles(item.id)"
            >
              {{ $t('save.title') }}
            </v-btn>
          </div>
          <span class="role-displayed" v-else v-text="item.roleDisplayed" />
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn v-if="item.id !== user.id && item.id !== rootUserID"
                 icon
          >
            <v-icon @click="openDeleteMemberDialog(item.id)">
              mdi-delete
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:item.activated="{ item }">
          <div class="activation-status">
            <div class="tooltip tooltip-top" :data-tooltip="$t(`team.users.activated_tooltip.${item.activated}`)">
              <v-icon v-text="item.activated ? 'mdi-check-circle' : 'mdi-timelapse'" />
            </div>
            <template v-if="hasEditPermission && !item.activated">
              <a v-if="!item.resentActivation"
                 @click="resendActivationEmail(item.id)"
                 :disabled="item.resendDisabled"
                 v-text="$t('team.users.resend_email')"
              />
              <span v-else v-html="$t('team.users.email_sent')" />
            </template>
          </div>
        </template>
      </v-data-table>

      <v-card-actions v-if="!loading && !failed">
        <v-spacer />
        <v-btn color="primary"
               v-if="newMemberAvailable && hasEditPermission"
               @click="openNewMemberDialog"
        >
          {{ $t('team.users.btn_new') }}
        </v-btn>
        <v-alert v-else-if="!newMemberAvailable"
                 type="warning"
                 text
                 border="left"
                 class="flex-center"
        >
          {{ $t('team.new_member.limit_reached', { n: maxAccountsAvailble }) }}
          <v-btn v-if="$root.isRootAccount"
                 color="white"
                 class="manage-subscription"
                 light
                 dense
                 small
                 style="margin-left: 12px"
                 :to="{ name: 'account', query: { tab: 'subscription' } }"
          >
            {{ $t('feature_not_included.btn') }}
          </v-btn>
        </v-alert>
      </v-card-actions>
    </v-card>

    <!-- DIALOG -->
    <v-dialog v-model="dialogActive"
              max-width="500"
              @keydown.esc="cancelDialog"
              :persistent="dialog.loading"
    >
      <v-card class="dialog-team" v-if="!!dialog.mode">
        <v-card-title class="headline grey lighten-2"
                      primary-title
                      v-text="$t(`team.${dialog.mode}.title`)"
        />

        <div v-show="!!dialog.success" class="alert-container">
          <v-alert type="success"
                   border="left"
                   text
          >
            <span v-html="dialog.success" />
          </v-alert>
        </div>

        <v-expand-transition>
          <div v-show="dialog.error !== ''" class="alert-container">
            <v-alert type="error"
                     border="left"
                     text
            >
              {{ dialog.error }}
            </v-alert>
          </div>
        </v-expand-transition>

        <v-card-text v-if="!dialog.success">
          <v-form
            v-model="dialogValid"
            @submit.prevent
            @keydown.native.enter="submitDialog"
            ref="form"
          >
            <template v-if="dialog.mode === 'new_org'">
              <v-text-field v-model="dialog.orgName"
                            :label="$t('team.new_org.label')"
                            :disabled="dialog.loading"
                            maxlength="30"
                            :rules="orgNameRules"
                            counter
                            outlined
                            ref="orgName"
              />
            </template>
            <template v-else-if="dialog.mode === 'new_member'">
              <v-alert v-if="user.has_sso" type="info" border="left" text>
                <div v-html="$t('team.new_member.has_sso')" />
              </v-alert>

              <v-alert type="info" border="left" text>
                {{ $t('team.new_member.info') }}
              </v-alert>

              <v-text-field v-model="dialog.email"
                            type="email"
                            :label="$t('team.new_member.email')"
                            :disabled="dialog.loading"
                            :rules="emailRules"
                            :error-messages="dialog.fieldErrors.email"
                            outlined
                            ref="email"
                            data-private
              />

              <div style="display: flex">
                <v-text-field v-model="dialog.firstName"
                              :label="$t('team.new_member.first_name')"
                              :disabled="dialog.loading"
                              maxlength="30"
                              :rules="firstNameRules"
                              :error-messages="dialog.fieldErrors.first_name"
                              counter
                              outlined
                              ref="firstName"
                              data-private
                              style="margin-right: 8px"
                />

                <v-text-field v-model="dialog.lastName"
                              :label="$t('team.new_member.last_name')"
                              :disabled="dialog.loading"
                              :rules="lastNameRules"
                              :error-messages="dialog.fieldErrors.last_name"
                              maxlength="30"
                              counter
                              outlined
                              ref="lastName"
                              data-private
                />
              </div>

              <v-select v-model="dialog.role"
                        :disabled="dialog.loading"
                        hide-details
                        :label="$t('team.users.roles')"
                        outlined
                        :items="roleSelect"
                        :menu-props="{ 'contentClass': 'select-menu-with-helptips' }"
                        item-disabled="disabled"
              >
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text" />
                  </v-list-item-content>
                  <helptip position="bottom"
                           :width="250"
                           :anchor-icon="item.disabled ? 'mdi-cancel' : undefined"
                  >
                    <feature-unavailable v-if="!item.available" />
                    <span v-else-if="!item.settable"
                          v-html="$t('permission.no_permission', {
                            email: user.organization.root_user_email
                          } )"
                    />
                    <div v-else>
                      <span v-html="item.description" />
                      <a @click="showRolesBrowser = true" v-text="$t('team.roles_browser_btn')" />.
                    </div>
                  </helptip>
                </template>
              </v-select>
            </template>

            <template v-else-if="dialog.mode === 'delete_member'">
              <v-alert type="warning"
                       class="confirm-delete"
                       outlined
                       text
                       border="left"
                       data-private
              >
                <div v-html="$t(`team.delete_member.warning`, { user: $escapeHtml(deleteUser.name) })" />
              </v-alert>

              <v-radio-group v-model="dialog.transferAssets">
                <v-radio :value="true">
                  <span slot="label" v-html="$t('team.delete_member.transfer_assets.true')" />
                </v-radio>
                <v-radio :value="false">
                  <span slot="label" v-html="$t('team.delete_member.transfer_assets.false')" />
                </v-radio>
              </v-radio-group>

              <div class="delete-message" v-html="confirmDeleteMesssage" />

              <v-text-field v-model="dialog.deleteConfirmation"
                            :label="$t('team.delete_member.confirm_delete_label')"
                            :disabled="dialog.loading"
                            :rules="deleteConfirmationRules"
                            :error-messages="dialog.fieldErrors.delete_confirmation"
                            class="confirm-delete-input mb-n5"
                            outlined
                            data-private
              />
            </template>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary"
                 outlined
                 @click="cancelDialog"
                 :disabled="dialog.loading"
          >
            {{ !!dialog.success ? $t('close') : $t('cancel') }}
          </v-btn>
          <v-btn :color="dialog.mode === 'delete_member' ? 'error' : 'primary'"
                 :loading="dialog.loading"
                 :disabled="!dialogValid || !!dialog.success"
                 @click.prevent.stop="submitDialog"
          >
            {{ dialog.mode === 'delete_member' ? $t('delete.title') : $t('save.title') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <roles-browser v-model="showRolesBrowser" :roles="roles" />
  </div>
</template>

<script>

import Vuex from 'vuex'
import axios from 'axios'

import RolesBrowser from '@/components/account/RolesBrowser'
import { USER_SETTINGS_TEMPLATE } from '@/settings/constants'
import { HELP_RESOURCE_CREDITS } from '@/settings/help-resources'
import { overridePropsIfExistRecursive } from '@/utils/funcs'

export default {
  name: 'ManageTeam',
  codit: true,

  components: {
    'roles-browser': RolesBrowser
  },

  data () {
    return {
      loading: false,
      failed: false,

      organization: {
        name: '',
        user_settings: _.cloneDeep(USER_SETTINGS_TEMPLATE)
      },
      organizationDirty: false,
      savingOrganization: false,

      teamMembers: [],
      roles: [],
      savingRole: false,

      organizationFormValid: false,
      dialogValid: false,

      dialog: {
        active: false,
        loading: false,
        success: false,
        error: '',
        fieldErrors: {},
        mode: '',
        deleteID: '',
        deleteConfirmation: '',
        transferAssets: true,
        orgName: '',
        firstName: '',
        lastName: '',
        email: '',
        role: -1
      },

      showRolesBrowser: false,

      orgNameRules: [v => v.length >= 3 || this.$t('team.err_too_short', { n: 3 })],
      firstNameRules: [v => v.length >= 3 || this.$t('team.err_too_short', { n: 3 })],
      lastNameRules: [v => v.length >= 3 || this.$t('team.err_too_short', { n: 3 })],
      emailRules: [v => v.length >= 4 || this.$t('team.err_email')],
      deleteConfirmationRules: [v => v.toLowerCase() === this.confirmDeleteMesssage.toLowerCase() || this.$t('team.err_confirmation')],

      setOrganizationDirtyDebounced: _.debounce(() => { if (!this.organizationDirty) this.organizationDirty = true }, 250),

      HELP_RESOURCE_CREDITS
    }
  },

  computed: {
    /**
     * Proxy for the `dialog.active` property.
     * Make sure the cancelDialog is called when closing the dialog, to clear current inputs.
     */
    dialogActive: {
      get () { return this.dialog.active },
      set (val) { if (!val) this.cancelDialog() }
    },

    orgName () {
      return this.user.organization.name
    },

    rootUserID () {
      return this.user.organization.root_user_id
    },

    rootUserName () {
      return this.user.organization.root_user_name
    },

    rootUserEmail () {
      return this.user.organization.root_user_email
    },

    hasEditPermission () {
      return this.$hasPermission('team_mgmt')
    },

    /**
     * The number of accounts that are available with the user's subscription
     * A value of -1 indicates unlimited
     * @return {Number}
     */
    maxAccountsAvailble () {
      return this.user.subscription.features.max_users
    },

    /**
     * If a new member can be added (is within the account limit of the subscription)
     * @return {Boolean}
     */
    newMemberAvailable () {
      return this.maxAccountsAvailble === -1 || this.teamMembers.length < this.maxAccountsAvailble
    },

    /**
     * The roles the current user has
     * @return {String}
     */
    userRoles () {
      return _.map(this.user.roles, r => this.getRoleName(this.roleNamesByID[r])).join(', ')
    },

    /**
     * The description of the first (and probably only) role this user has
     * @return {String}
     */
    userRoleDescription () {
      let roleID = this.user.roles[0]
      let role = _.find(this.roles, { id: roleID })
      if (!role) return ''
      else if (role.owner) return this.$t('roles_browser.custom_role_description')
      else return this.$t(`roles.${this.roleNamesByID[roleID]}.description`)
    },

    roleNamesByID () {
      let mapping = {}
      this.roles.forEach(role => { mapping[role.id] = role.name })
      return mapping
    },

    roleSelect () {
      return _.map(this.roles, r => ({
        value: r.id,
        text: this.getRoleName(r.name),
        description: this.$te(`roles.${r.name}.name`)
          ? this.$t(`roles.${r.name}.description`)
          : this.$t('roles_browser.custom_role_description'),
        available: r.available,
        settable: r.settable,
        disabled: !r.available || !r.settable
      }))
    },

    userTableHeaders () {
      let headers = [
        { text: this.$t('team.users.user'), value: 'name' },
        { text: this.$t('team.users.date_joined'), value: 'date_joined' },
        { text: this.$t('team.users.last_login'), value: 'last_login' },
        { text: this.$t('team.users.activation_status'), value: 'activated', align: 'center' },
        { text: this.$t('team.users.roles'), value: 'roleDisplayed', align: 'left' }
      ]
      if (this.hasEditPermission) headers.push({ text: '', value: 'actions', align: 'end', sortable: false })
      return headers
    },

    confirmDeleteMesssage () {
      return this.$t('team.delete_member.confirm_delete_text', { user: this.$escapeHtml(this.deleteUser.first_name) })
    },

    /**
     * The user object which is marked for deletion
     * @return {Object} The user object
     */
    deleteUser () { return _.find(this.teamMembers, { id: this.dialog.deleteID }) || {} },

    ...Vuex.mapState(['user'])
  },

  watch: {
    'dialog.firstName' () { delete this.dialog.fieldErrors.first_name },
    'dialog.lastName' () { delete this.dialog.fieldErrors.last_name },
    'dialog.email' () { delete this.dialog.fieldErrors.email },
    'dialog.deleteConfirmation' () { delete this.dialog.fieldErrors.email }
  },

  created () {
    this.initialLoad()
  },

  methods: {
    /**
     * Helper method, resetting the dialog properties to default values
     */
    _resetDialogProps () {
      this.dialog.loading = false
      this.dialog.success = false
      this.dialog.error = ''
      this.dialog.fieldErrors = {}
      this.dialog.mode = ''
      this.dialog.deleteID = ''
      this.dialog.deleteConfirmation = ''
      this.dialog.transferAssets = true
      this.dialog.orgName = ''
      this.dialog.firstName = ''
      this.dialog.lastName = ''
      this.dialog.email = ''
      this.dialog.role = -1
    },

    initialLoad () {
      if (!this.user.organization) return
      this.failed = false
      this.loading = true

      let requests = [this.loadTeamMembers(), this.loadRoles()]
      if (this.hasEditPermission) requests.push(this.loadOrganization())

      axios.all(requests).then(() => {
        this.teamMembers.forEach(user => {
          let currentRole
          // If the user is root, we'll give him the fake role ID 0
          if (user.id === this.user.organization.root_user_id) currentRole = 0
          // If he has one or more roles, we'll take the first one
          // (FE only allows assigning one role at the time, we'll just ignore the rest)
          else if (user.roles.length) currentRole = user.roles[0]
          // Otherwise he doesn't have any role at all currently
          else currentRole = null
          // Try to put a name to the current role, which is used for sorting and displaying
          // when roles are non-editable
          let currentRoleName = currentRole === 0 ? 'root' : this.roleNamesByID[currentRole]
          this.$set(user, 'roleDisplayed', currentRoleName ? this.getRoleName(currentRoleName) : '')
          this.$set(user, 'currentRole', currentRole)
          this.$set(user, 'newRole', currentRole)
        })
        this.loading = false
      })
    },

    /**
     * Load the team members
     * @return {Promise}  Promise for the api response
     */
    loadTeamMembers () {
      return api.get('/api/team/members/').then(res => {
        this.$set(this, 'teamMembers', res.data)
        return res
      }).catch(err => {
        this.loading = false
        this.failed = true
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Load the roles
     * @return {Promise}  Promise for the api response
     */
    loadRoles () {
      return api.get('/api/team/roles/').then(res => {
        this.$set(this, 'roles', res.data)
        return res
      }).catch(err => {
        this.loading = false
        this.failed = true
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Load the organization
     * @return {Promise}  Promise for the api response
     */
    loadOrganization () {
      return api.get('/api/team/organization').then(res => {
        let organization = _.cloneDeep(this.organization)
        overridePropsIfExistRecursive(organization, res.data)
        this.$set(this, 'organization', organization)
        return res
      }).catch(err => {
        this.loading = false
        this.failed = true
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Save changes to organization
     */
    saveOrganization () {
      if (
        !this.$refs.organizationForm.validate()
      ) {
        return
      }

      this.savingOrganization = true
      api.patch(`/api/team/organization`, this.organization).then(res => {
        if (res.status === 200) {
          this.savingOrganization = false
          this.organizationDirty = false
          this.$root.snackMsg(this.$t('team.settings.success'))
          this.$root.getUser() // Update potentially changed user_settings
        } else throw Error('Something went wrong saving the organization')
      }).catch(err => {
        this.$root.snackMsg(this.$t('error_generic'))
        this.savingOrganization = false
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Get the role of a name: The translation value (if it exists) or just the name by default
     * @param  {String} rName The string identifier of the name
     * @return {String}       The formatted name
     */
    getRoleName (rName) {
      return this.$te(`roles.${rName}.name`) ? this.$t(`roles.${rName}.name`) : rName
    },

    openNewOrgDialog () {
      this.openDialog('new_org')
    },

    openNewMemberDialog () {
      this.openDialog('new_member')
      // Set the current role as the first role which is available
      // This makes sure the user gets a role
      let firstRole = _.find(this.roles, { available: true, settable: true })
      this.dialog.role = firstRole ? firstRole.id : null
    },

    openDeleteMemberDialog (userID) {
      this.openDialog('delete_member')
      this.dialog.deleteID = userID
    },

    openDialog (mode) {
      this._resetDialogProps()
      this.dialog.mode = mode
      this.dialog.active = true
    },

    saveUserRoles (userID) {
      let user = _.find(this.teamMembers, { id: userID })
      if (!user) throw Error(`Could not find user id=${userID}`)
      this.savingRole = true
      api.patch(`/api/team/members/${userID}`, { roles: [user.newRole] }).then(res => {
        if (res.status === 200) {
          this.savingRole = false
          user.currentRole = user.newRole
          this.$root.snackMsg(this.$t('team.roles_saving_success'))
        } else throw Error('Something went wrong saving user roles')
      }).catch(err => {
        this.$root.snackMsg(this.$t('error_generic'))
        this.savingRole = false
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    resendActivationEmail (userID) {
      let user = _.find(this.teamMembers, { id: userID })
      this.$set(user, 'resendDisabled', true)
      if (!user) throw Error(`Could not find user id=${userID}`)
      api.patch(`/api/team/members/${userID}`, { resend_confirmation: true }).then(res => {
        if (res.status === 200) this.$set(user, 'resentActivation', true)
        else throw Error('Something went wrong sending activation')
      }).catch(err => {
        user.resendDisabled = false
        this.$root.snackMsg(this.$t('error_generic'))
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Cancel the current dialog and reset the props
     */
    cancelDialog () {
      if (this.dialog.loading) return
      this._resetDialogProps()
      this.dialog.active = false
    },

    submitDialog () {
      if (
        !this.$refs.form.validate()
      ) {
        return
      }

      this.dialog.loading = true
      this.dialog.error = ''
      this.$set(this.dialog, 'fieldErrors', {})
      switch (this.dialog.mode) {
        case 'new_org':
          this.submitNewOrg()
          break
        case 'new_member':
          this.submitNewMember()
          break
        case 'delete_member':
          this.submitDeleteMember()
          break
        default:
          throw Error(`Unknown submit mode ${this.dialog.mode}`)
      }
    },

    submitNewOrg () {
      let catchFn = err => {
        this.dialog.loading = false
        this.dialog.error = this.$t('error_generic')
        this.$maybeRaiseAPIPromiseErr(err)
      }
      api.post('/api/team/organization', { name: this.dialog.orgName }).then(res => {
        if (res.status === 201) {
          this.$root.getUser().then(() => {
            this.dialog.loading = false
            this.initialLoad()
            this.cancelDialog()
            this.$root.snackMsg(this.$t('team.new_org.success'))
          }).catch(catchFn)
        } else catchFn(`Invalid return status ${res.status}`)
      }).catch(catchFn)
    },

    submitNewMember () {
      let postData = {
        email: this.dialog.email,
        first_name: this.dialog.firstName,
        last_name: this.dialog.lastName,
        roles: [this.dialog.role]
      }

      api.post('/api/team/members/create', postData, { dontReport: [400] }).then(res => {
        if (res.status === 201) {
          this.initialLoad()
          this.dialog.success = this.$t('team.new_member.success')
        } else if (res.data && 'error' in res.data) {
          this.dialog.error = res.data.error
        } else if (_.keys(res.data).length) {
          this.$set(this.dialog, 'fieldErrors', res.data || {})
          this.dialog.error = this.$t('team.err_user')
        } else {
          this.dialog.error = this.$t('error_generic')
          this.$maybeRaiseAPIPromiseErr(Error(`can't handle error`))
        }
        this.dialog.loading = false
      }).catch(err => {
        this.dialog.loading = false
        this.dialog.error = this.$t('error_generic')
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    submitDeleteMember () {
      let deleteUser = _.cloneDeep(this.deleteUser)
      // By default, assets are transferred. So only pass param if disabled.
      let params = this.dialog.transferAssets ? '' : 'no_transfer_assets'
      api.delete(`/api/team/members/${deleteUser.id}?${params}`).then(res => {
        if (res.status === 204) {
          this.dialog.loading = false
          this.initialLoad()
          this.dialog.success = this.$t('team.delete_member.success', { user: this.$escapeHtml(deleteUser.name) })
        } else throw Error('Unexpected status code.')
      }).catch(err => {
        this.dialog.loading = false
        this.dialog.error = this.$t('error_generic')
        this.$maybeRaiseAPIPromiseErr(err)
      })
    }
  }
}

</script>

<style lang=scss>

.team-mgmt {
  margin-left: 8px;

  .members {
    margin: 0!important;
  }

  > .v-alert {
    margin-bottom: 8px;
  }

  .team-table {
    tr {
      height: 60px;
    }
    .activation-status {
      a {
        display: block;
      }
      // a, span {
      //   margin-left: 8px;
      //   border-left: 1px solid #DDD;
      //   padding-left: 8px;
      // }
    }

    .role-editor {
      display: flex;
      align-items: center;
      max-width: 250px;
      .v-select {
        font-size: smaller;
        .v-input__control {
          min-height: 30px;
        }
      }
      .v-btn {
        margin-bottom: -1px;
        margin-left: 8px;
        font-size: smaller;
        height: 32px;
      }
    }
    .role-displayed {
      font-size: smaller;
      margin-left: 12px;
    }
  }
}

.team-settings {
  margin: 0 0 8px 0!important;

  .v-card__text {
    display: flex;
    justify-content: space-between;
    .v-input {
      margin: 0;
    }
    .dummy-strings {
      display: flex;
      flex-wrap: wrap;
      .v-input {
        margin-right: 8px;
      }

      .helptip {
        margin-top: 8px;
      }
    }

    .col1 {
      min-width: 300px;
      flex: 1;
      max-width: 500px;
      margin-right: 24px;
      margin-top: 21px;
    }

    .default-settings {
      margin-top: -5px;
      border: 4px solid #EEE;
      background: #EEE;
      border-radius: 8px;
      display: flex;
      padding: 8px 12px;

      > legend {
        background: #FFF;
        border: 2px solid #EEE;
        border-radius: 4px;
        color: #666;
        font-size: smaller;
        padding: 0 8px;
        flex: 0 0 auto;
        white-space: nowrap;
        z-index: 4;
        position: relative;
      }
    }
  }
}

.dialog-team {
  .alert-container {
    padding: 8px 16px;
    .v-alert {
      margin: 0!important;
    }
  }

  .delete-message {
    padding: 8px 16px;
    margin-bottom: 12px;
    background: #EEE;
    border-radius: 4px;
    border: 1px solid #DDD;
    border-left: 3px solid #DDD;
    color: var(--v-error-base);
    font-weight: bold;
    text-align: center;
  }

  .confirm-delete-input {
    input { text-align: center }
  }
}

</style>

<i18n locale='en' src='@/i18n/en/components/account/ManageTeam.json' />
<i18n locale='de' src='@/i18n/de/components/account/ManageTeam.json' />
<i18n locale='en' src='@/i18n/en/Roles.json' />
<i18n locale='de' src='@/i18n/de/Roles.json' />
<i18n locale='en' src='@/i18n/en/pages/Account.json' />
<i18n locale='de' src='@/i18n/de/pages/Account.json' />