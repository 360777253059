<template>
  <div class="main-content slpg" style="margin-bottom: 80px">
    <project-list
      :external-projects="projects"
      :external-config="projectsConfig"
      :external-loading="projectsLoading"
      :external-failed="projectsFailed"
      :dont-hide-project-details-on-click="deleteQuestion.active || editQuestion.active"
      :project-to-focus="focusProject"
      :initial-state="initialState"
      :tag-selector-open="tagSelector.shown"
      add-enable
      show-tabs
      archived-filter-enable
      observe-users-online
      show-projects-per-page
      selectable
      @show-tag-selector="handleShowTagSelector"
      @filters="filtersHandler"
      @rows-per-page="rowsPerPageHandler"
      @refetch="refetch"
      @open-project="openProject"
      :title="$t('title')"
      ref="projectList"
    >
      <template slot="header-multiple-selected-actions" slot-scope="{ projectsSelected }">
        <v-btn
          icon
          class="tag-projects"
          :disabled="!projectsSelected.every(p => $hasPermission('projects_edit', p))"
          @click.native="e => handleShowTagSelector(e, projectsSelected)"
        >
          <v-icon>
            mdi-label-outline
          </v-icon>
        </v-btn>
        <v-menu
          :close-on-content-click="false"
          :rounded="'lg'"
          offset-y
          absolute
          :position-x="tagSelector.x"
          :position-y="tagSelector.y"
          v-model="tagSelector.shown"
          @input="() => $nextTick(() => handleTagSelectorClose())"
        >
          <div
            class="filters__list filters__list--values"
          >
            <v-text-field
              v-model="tagFilterSearch"
              single-line
              class="search-bar__search search-bar__search--borderless w-100 mr-0"
              dense
              :elevation="0"
              :label="$t('filters.tag_search_create_placeholder')"
              hide-details
            />
            <div class="filters__list__separator" />
            <div
              v-if="tags.length"
              class="filters__list__padding pt-3"
            >
              <div
                class="filters__list__check d-flex align-center"
                v-for="(tag, idx) in tags"
                :key="idx"
              >
                <v-checkbox
                  primary
                  hide-details
                  :ripple="false"
                  :color="tag.color"
                  off-icon="mdi-checkbox-blank"
                  @change="handleFilterTagChange(tag, projectsSelected)"
                  :input-value="tagIncludedInFilters(tag)"
                  flat
                >
                  <template v-slot:label>
                    <div
                      class="filters__list__check__indicator"
                      :style="`background-color: ${tag.color};`"
                    />
                    {{ tag.name }}
                  </template>
                </v-checkbox>
              </div>
            </div>
            <div class="filters__list__padding pt-3 text-center" v-else>
              <v-label>
                {{ $t('filters.tags_empty') }}
              </v-label>
              <div v-if="tagFilterSearch.length" class="d-flex justify-center mt-2">
                <v-btn
                  color="green"
                  elevation="0"
                  @click="createTag(projectsSelected)"
                >
                  {{ $t('filters.create_tag') }} '{{ tagFilterSearch }}'
                </v-btn>
              </div>
            </div>
          </div>
        </v-menu>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              :disabled="archiveIsDisabled(projectsSelected)"
              @click="archiveProjects(projectsSelected)"
            >
              <v-icon :size="23">
                mdi-archive-outline
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('actions.mark_completed') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="openDeleteProjects(projectsSelected)"
              :disabled="!projectsSelected.every(p => $hasPermission('projects_delete', p))"
              icon
            >
              <svg width="19" height="19" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.75 8C16.4404 8 17 8.55964 17 9.25V16.75C17 18.8211 15.3211 20.5 13.25 20.5H5.75C3.67893 20.5 2 18.8211 2 16.75V9.25C2 8.55964 2.55964 8 3.25 8H15.75ZM14.5 10.5H4.5V16.75C4.5 17.4404 5.05964 18 5.75 18H13.25C13.9404 18 14.5 17.4404 14.5 16.75V10.5ZM5.75 1.75C5.75 1.05964 6.30964 0.5 7 0.5H12C12.6904 0.5 13.25 1.05964 13.25 1.75V3H17C17.6904 3 18.25 3.55964 18.25 4.25C18.25 4.94036 17.6904 5.5 17 5.5H2C1.30964 5.5 0.75 4.94036 0.75 4.25C0.75 3.55964 1.30964 3 2 3H5.75V1.75Z" fill="#687181" />
              </svg>
            </v-btn>
          </template>
          <span>{{ $t('actions.delete') }}</span>
        </v-tooltip>
      </template>

      <template slot="header-actions" slot-scope="{ projectsSelected }">
        <v-btn
          :disabled="projectsSelected.length !== 1 || !$hasPermission('projects_download', projectsSelected[0])"
          @click="openDownloadV2(projectsSelected[0])"
          icon
          data-testid="showDownloadV2Button"
        >
          <v-icon left color="white">
            mdi-cloud-download
          </v-icon>
        </v-btn>

        <v-btn icon
               :disabled="projectsSelected.length !== 1 || projectsSelected[0].questions.length === 0 || !$hasPermission('projects_append', projectsSelected[0]) || !!projectsSelected[0].data_source"
               :to="{ name: 'project-append', params: { id: projectsSelected.length ? projectsSelected[0].id : -1 } }"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">
                mdi-file-plus
              </v-icon>
            </template>
            <span>{{ $t('actions.append') }}</span>
          </v-tooltip>
        </v-btn>

        <v-btn icon
               :disabled="projectsSelected.length !== 1 || !$hasPermission('projects_edit', projectsSelected[0]) || !!projectsSelected[0].data_source"
               :to="{ name: 'project-replace-auxiliaries', params: { id: projectsSelected.length ? projectsSelected[0].id : -1 } }"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">
                mdi-scissors-cutting
              </v-icon>
            </template>
            <span>{{ $t('actions.replace_auxiliaries') }}</span>
          </v-tooltip>
        </v-btn>

        <v-btn v-if="user.organization"
               icon
               :disabled="projectsSelected.length !== 1 || !$hasPermission('projects_permissions', projectsSelected[0])"
               @click="openPermissions(projectsSelected[0])"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">
                mdi-account-supervisor
              </v-icon>
            </template>
            <span>{{ $t('actions.permissions') }}</span>
          </v-tooltip>
        </v-btn>

        <v-btn icon
               :disabled="!projectsSelected.every(p => $hasPermission('projects_edit', p))"
               @click="openEditProjects(projectsSelected)"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('actions.edit') }}</span>
          </v-tooltip>
        </v-btn>

        <v-btn @click="openDeleteProjects(projectsSelected)"
               :disabled="!projectsSelected.every(p => $hasPermission('projects_delete', p))"
               icon
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('actions.delete') }}</span>
          </v-tooltip>
        </v-btn>

        <v-btn v-if="$root?.posthogFlagEnabled?.('conversational_insights')"
               :href="`/insightspoc/?project_id=${projectsSelected[0].id}`"
               target="_blank"
               icon
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">
                mdi-robot
              </v-icon>
            </template>
            <span>Conversational Insights</span>
          </v-tooltip>
        </v-btn>

        <new-app-link>
          <template #default="{ newAppUrl }">
            <v-btn
              :href="`${newAppUrl}/projects/${projectsSelected[0].id}`"
              target="_blank"
              icon
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    mdi-page-next-outline
                  </v-icon>
                </template>
                <span>{{ $t('actions.view_in_v3') }}</span>
              </v-tooltip>
            </v-btn>
          </template>
        </new-app-link>
      </template>

      <template slot="action" slot-scope="{ question }">
        <div style="display: flex">
          <v-btn v-if="!question.ncodes"
                 outlined
                 color="green"
                 :disabled="!v2identifier.questions_to_ref[question.id]"
                 :to="{
                   name: 'question-wizard-v2',
                   params: { id: v2identifier.project_id, ref: v2identifier.questions_to_ref[question.id] }
                 }"
                 exact
                 style="flex: 1"
          >
            {{ $t('actions.init') }}
          </v-btn>

          <v-btn v-else
                 outlined
                 color="green"
                 :to="{ name: 'question-cockpit', params: { id: question.id } }"
                 exact
                 style="flex: 1"
          >
            {{ $t('actions.cockpit') }}
          </v-btn>

          <v-menu bottom left>
            <template v-slot:activator="{ on }">
              <v-btn icon
                     v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :disabled="!v2identifier.questions_to_ref[question.id]"
                :to="{
                  name: 'coding-v2',
                  params: { id: v2identifier.project_id, ref: v2identifier.questions_to_ref[question.id] }
                }"
              >
                <v-list-item-title>{{ $t('actions.coding_view') }}</v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'charts-manage', query: {
                             questionID: question.id, questionName: question.name, projectName: question.project_obj.name
                           } }"
                           :disabled="!question.ncodes"
              >
                <v-list-item-title>
                  {{ $t('actions.visualizations_view') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                :disabled="!v2identifier.questions_to_ref[question.id]"
                :to="{
                  name: 'question-wizard-v2',
                  params: { id: v2identifier.project_id, ref: v2identifier.questions_to_ref[question.id] }
                }"
              >
                <v-list-item-title>
                  {{ $t('actions.codebook_wizard') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>

      <template slot="question-actions" slot-scope="{ question }">
        <v-btn @click="openEditQuestion(question)"
               :disabled="!$hasPermission('projects_edit', question)"
               icon
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('actions.edit') }}</span>
          </v-tooltip>
        </v-btn>
      </template>
    </project-list>

    <!-- ====== Edit projects DIALOG ====== -->
    <v-dialog v-model="editProject.active" max-width="600" @keydown.esc="closeEditProject(false)" content-class="slpg">
      <v-card>
        <v-card-title class="headline"
                      v-html="$t('edit.dialog_title', { name: $escapeHtml(editProject.originalName),
                                                        item: $tc('project.items', projectsSelectedCached.length) })"
        />
        <v-card-text>
          <v-text-field
            v-model="editProject.name"
            :disabled="projectsSelectedCached.length>1"
            :error-messages="editProject.nameInvalid"
            :label="$t('eprops.name')"
            :counter="projectsSelectedCached.length===1"
            maxlength="50"
            dense
            outlined
          />

          <div v-if="editProject.data_source" class="d-flex align-center">
            <v-switch
              v-model="editProject.data_source.sync_enabled"
              :disabled="projectsSelectedCached.length>1"
              inset
              color="green"
              flat
              dense
              :ripple="false"
              class="v-input--reverse w-100 mt-0 mb-3 mt-1"
              hide-details
            >
              <template #label>
                {{ $t('project.interval.label') }}
                <helptip position="bottom" class="ml-1">
                  <div v-html="$t('project.interval.helptip')" />
                  <div>
                    <a
                      target="_blank"
                      class="mt-1"
                      href="https://caplena.com/docs/knowledge-base/2784zjo1mosah-integrations-in-detail" v-text="$t('actions.more_information')"
                    />
                  </div>
                </helptip>
              </template>
            </v-switch>
          </div>

          <div class="hide-completed-checkbox-container">
            <v-checkbox v-model="editProject.completed"
                        :label="$t('eprops.status.completed')"
                        :indeterminate="editProject.completedIndeterminate"
                        @change="editProject.completedIndeterminate=false"
            />

            <helptip position="top" :width="400">
              <span v-html="$t('edit.completed_helptip')" />
            </helptip>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" outlined @click.native="closeEditProject(false)">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="primary" @click.native="closeEditProject(true, true, true)">
            {{ $t('ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ====== Delete projects DIALOG ====== -->
    <v-dialog v-model="deleteProject.active" max-width="600" @keydown.esc="closeDeleteProjects(false)">
      <v-card>
        <v-card-title class="headline" primary-title>
          <div v-html="$t('delete.are_you_sure', { type: $tc('project.items', projectsSelectedCached.length),
                                                   name: $escapeHtml(deleteProject.label) })"
          />
        </v-card-title>

        <alert v-if="$_.filter(projectsSelectedCached, p => p.inherits_to || p.inherits_to_and_from).length" type="warning">
          <span v-html="$t('delete_inherit_warn_project')" />
        </alert>

        <v-card-text v-html="$t('delete.description')" />

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" outlined @click.native="closeDeleteProjects(false)">
            {{ $t('no') }}
          </v-btn>
          <v-btn color="primary" @click.native="closeDeleteProjects(true)">
            {{ $t('yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ====== Edit Question DIALOG ====== -->
    <v-dialog v-model="editQuestion.active" max-width="600" @keydown.esc="closeEditQuestion(false)" content-class="slpg">
      <v-card>
        <v-card-title class="headline"
                      v-html="$t('edit.dialog_title', { name: $escapeHtml(editQuestion.originalName),
                                                        item: $t('question.item') })"
        />

        <v-card-text>
          <v-text-field v-model="editQuestion.name"
                        :error-messages="editQuestion.nameInvalid"
                        :label="$t('eprops.name')"
                        counter
                        maxlength="50"
          />

          <v-textarea v-model="editQuestion.description"
                      :label="$t('eprops.description')"
                      rows="2"
                      counter
                      maxlength="255"
          />

          <div class="hide-completed-checkbox-container">
            <v-checkbox v-model="editQuestion.completed"
                        :label="$t('eprops.status.completed')"
            />

            <helptip position="top" :width="400">
              <span v-html="$t('edit.completed_helptip')" />
            </helptip>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" outlined @click.native="closeEditQuestion(false)">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="primary" @click.native="closeEditQuestion(true)">
            {{ $t('ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ====== Delete question DIALOG ====== -->
    <!-- not in use -->
    <v-dialog v-model="deleteQuestion.active" max-width="600" @keydown.esc="closeDeleteQuestion(false)">
      <v-card>
        <v-card-title class="headline" primary-title>
          <div v-if="deleteQuestion.active" v-html="$t('delete.are_you_sure', { type: $t('question.item'),
                                                                                name: $t('question.of_project', {
                                                                                  project: $escapeHtml(deleteQuestion.question.project_obj.name),
                                                                                  question: $escapeHtml(deleteQuestion.label) }) })"
          />
        </v-card-title>

        <alert v-if="deleteQuestion.active && deleteQuestion.question.inherits_to_objs.length" type="warning">
          <span v-html="$t('delete_inherit_warn_question')" />
        </alert>

        <v-card-text v-html="$t('delete.description')" />

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" outlined @click.native="closeDeleteQuestion(false)">
            {{ $t('no') }}
          </v-btn>
          <v-btn color="primary" @click.native="closeDeleteQuestion(true)">
            {{ $t('yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ====== Download DIALOG ====== -->
    <download-dialog v-model="download.active"
                     :credits-open="download.creditsOpen"
                     :entity-id="download.project"
                     entity-type="project"
                     :nanswers="download.nanswers"
                     @downloaded="projectDownloaded"
    />

    <export-data-dialog
      v-if="v2identifier.project_id"
      v-model="download.activeV2"
      :project-id="v2identifier.project_id"
      :project-name="currentProjectName"
      data-testid="exportDataDialog"
    />

    <!-- ====== Permissions DIALOG ====== -->
    <permission-editor v-model="permissionEditor.active"
                       :entity-id="permissionEditor.projectID"
                       :entity-name="permissionEditor.projectName"
                       :entity-owner-id="permissionEditor.projectOwnerID"
                       :permission-options="permissionEditor.permissionOptions"
                       @change-ownership="changeOwnership"
                       entity-type="project"
    />
  </div>
</template>

<script>
import axios from 'axios'

import NewAppLink from '@/components/NewAppLink'
import ProjectListComponent from '@/components/ProjectList'
import DownloadDialog from '@/components/coding/DownloadDialog'
import ExportDataDialog from '@/components/coding/ExportData/ExportDataDialog.vue'
import PermissionEditor from '@/components/PermissionEditor'
import ProjectListMixin from '@/mixins/ProjectList'
import LocalstorageMixin from '@/mixins/localstorage'
import { omitDeep } from '@/utils/funcs'
import { createQueryObjFromFilters } from '@/utils/filters'

import Vuex from 'vuex'

import {
  QUESTION_CATEGORY_NONE,
  MIN_PROJECT_NAME_LEN,
  MIN_QUESTION_NAME_LEN,
  PROJECT_NAME_DEMO_OPEN,
  PROJECT_NAME_DEMO_LIST,
  PROJECT_LIST_FILTERS_DEFAULT as FILTERS_DEFAULT
} from '@/settings/constants'

export default {
  codit: true,
  name: 'ManageProjects',
  components: {
    'project-list': ProjectListComponent,
    'download-dialog': DownloadDialog,
    'export-data-dialog': ExportDataDialog,
    'permission-editor': PermissionEditor,
    'new-app-link': NewAppLink
  },
  mixins: [ProjectListMixin, LocalstorageMixin],
  data () {
    return {
      projectsSelectedCached: [],
      v2identifier: {
        project_id: '',
        questions_to_ref: {}
      },

      initialState: {
        filters: {}
      },

      filters: undefined,

      editProject: {
        active: false,
        originalName: '',
        name: '',
        nameInvalid: '',
        // language: '',
        labels: [],
        labelsChanged: false,
        completed: false,
        completedIndeterminate: false,
        data_source: null
      },

      deleteProject: {
        active: false,
        label: ''
      },

      deleteQuestion: {
        question: {},
        active: false,
        label: ''
      },

      editQuestion: {
        active: false,
        originalName: '',
        name: '',
        nameInvalid: '',
        description: '',
        completed: false,
        question: {}
      },

      projects: [],

      download: {
        active: false,
        activeV2: false,
        project: '',
        nanswers: 0,
        creditsOpen: 0
      },

      permissionEditor: {
        active: false,
        projectID: '',
        projectName: '',
        projectOwnerID: '',
        permissionOptions: [
          'projects_view',
          'projects_download',
          'projects_edit',
          'projects_permissions',
          'projects_inherit',
          'projects_append',
          'projects_delete'
        ]
      },

      tagSelector: {
        shown: false,
        x: null,
        y: null
      },
      tagFilterSearch: '',
      tagsSelected: [],

      QUESTION_CATEGORY_NONE,

      maybeUpdateQueryDebounced: _.debounce(this.maybeUpdateQuery, 50)
    }
  },

  computed: {
    /**
     * Transform labels by value into idx for color fetching
     * @return {Object}
     */
    label2idx () {
      let lbl2idx = {}
      this.projectsConfig.tabs.slice(2).forEach((lbl, idx) => { lbl2idx[lbl.value] = idx })
      return lbl2idx
    },

    /**
     * Tags with their respective colors
     * @return {Array}
     */
    tags () {
      const tags = _.map(this.projectsConfig.tabs.slice(2), label => ({
        ...label,
        color: this.$color.getStrong(this.label2idx[label.value])
      }))

      if (!this.tagFilterSearch.length) return tags
      return _.filter(tags, tag => _.includes(_.lowerCase(tag.name), _.lowerCase(this.tagFilterSearch)))
    },

    focusProject () {
      return this.$route.params.id || null
    },

    labels: {
      cache: false,
      get () { return this.$refs.projectList ? this.$refs.projectList.labels : [] }
    },

    /**
     * The computed url query, given the current pagination and filters
     * VueRouter takes care of encoding by default
     * @return {Object}
     */
    urlQuery () {
      return createQueryObjFromFilters(this.filters)
    },

    ...Vuex.mapState(['user']),

    currentProjectName () {
      return _.find(this.projects, ({ id }) => id === this.focusProject)?.name
    }
  },

  watch: {
    'editProject.active': 'dialogActivityChanged',
    'deleteProject.active': 'dialogActivityChanged',

    urlQuery () {
      if (this.focusProject) return
      this.maybeUpdateQueryDebounced()
    }
  },

  created () {
    // this.$store.commit('setPageTutorialID', '1592482306uGge8899')
  },

  beforeRouteUpdate (to, from, next) {
  // When the route changes, there are two options:
  // 1) Route change was triggered because we updated the query here - then do nothing
  // 2) Route change is due to back / forward button: Then set initial state
    if (!this.routeUpdatedFromHere) {
      this.$nextTick(() => {
        this.setInitialState(to.query)
        this.filters = _.cloneDeep(FILTERS_DEFAULT)

        this.loadProjects()
          .then(() => {
            this.projectsLoading = false
          })
      })
    }
    this.routeUpdatedFromHere = false
    next()
  },

  mounted () {
    this.loadAllData(page => {
      if (page !== 1) {
        this.$set(this.filters.pagination, 'page', page)
        this.maybeUpdateQuery(false)
      }
    })
    if (this.focusProject) this.getV2identifiers(this.focusProject)
  },

  methods: {
    refetch () {
      this.loadAllData(page => {
        if (page !== 1) {
          this.$set(this.filters.pagination, 'page', page)
          this.maybeUpdateQuery(false)
        }
      })
    },

    /**
     * Title for tag editor window
     */
    getProjectsSelectedLabel (projectsSelected) {
      return (projectsSelected.length < 2) ? projectsSelected[0].name : `(${projectsSelected.length})`
    },

    handleTagSelectorClose () {
      if (!this.tagSelector.shown) {
        this.tagsSelected = []
        this.$refs.projectList.clearSelected()
      }
    },

    handleShowTagSelector (e, projectsSelected, left = false) {
      e.preventDefault()
      if (this.tagSelector.shown) {
        this.tagSelector.shown = false
        return
      }
      this.tagSelector.x = e.clientX - (left ? 350 : 0)
      this.tagSelector.y = e.clientY
      this.tagSelector.shown = true
      this.handleTagSelectorInput(projectsSelected)
    },

    /**
     * If the archive button is disabled
     * @return {Boolean}
     */
    archiveIsDisabled (projectsSelected) {
      return !projectsSelected.every(p => this.$hasPermission('projects_edit', p)) || projectsSelected.every(p => p.completed)
    },

    /**
     * Function which fires on open / close of tag selector v-menu
     * sets proper selected tags based on selected projects
     */
    handleTagSelectorInput (projectsSelected) {
      if (!this.tagSelector.shown) this.tagsSelected = []
      else {
        // Generate selected tags
        const tagValues = _.map(this.projectsConfig.tabs.slice(2), ({ value }) => value)

        _.forEach(tagValues, tag => {
          if (_.every(projectsSelected, ({ labels }) => _.includes(labels, tag))) {
            this.tagsSelected = [...this.tagsSelected, tag]
          }
        })
      }
    },

    /**
     * Checkbox value in the tag filter selector
     * @return {Boolean}
     */
    tagIncludedInFilters (tag) {
      if (_.includes(this.tagsSelected, tag.value)) return true
      return false
    },

    /**
     * Toggle tag filter checkbox change
     */
    handleFilterTagChange (tag, projectsSelected) {
      let newTagFilters = [...this.tagsSelected]

      if (_.includes(newTagFilters, tag.value)) {
        newTagFilters = _.filter(newTagFilters, t => t !== tag.value)
      } else {
        newTagFilters.push(tag.value)
      }

      this.tagsSelected = newTagFilters

      this.tagProjects(projectsSelected)
    },

    /**
     * Handler when a project is opened in the sidebar.
     * @param  {Number} options.id   The ID of the opened project
     * @param  {String} options.name The name of the opened project
     */
    openProject ({ id, name } = {}) {
      // By default we add a new route
      // But when it's  a demo question, we replace the current ID with the new one
      if (id !== undefined) this.getV2identifiers(id)

      let method = 'push'
      if (name === PROJECT_NAME_DEMO_OPEN) {
        if (this.$route.params.id === 'demo-open') return
        method = this.$route.params.id === id ? 'replace' : 'push'
        id = 'demo-open'
      } else if (name === PROJECT_NAME_DEMO_LIST) {
        if (this.$route.params.id === 'demo-list') return
        method = this.$route.params.id === id ? 'replace' : 'push'
        id = 'demo-list'
      }

      // If we're already on this route, do nothing
      if (id === this.$route.params.id || (id === undefined && this.$route.params.id === undefined)) return
      this.routeUpdatedFromHere = true

      this.$router[method]({
        name: `projects-manage${id ? '-id' : ''}`,
        params: { id },
        query: this.$router.currentRoute.query
      })
      this.$store.commit('setBreadcrumbProps', { projectID: id, projectName: name })

      // Get the v2 identifier
      this.v2identifier.project_id = ''
      this.v2identifier.questions_to_ref = {}
    },

    // Safety measure: Make sure the selected projects are cleared, so no relics can show up during next edit step
    dialogActivityChanged (n, o) { if (!n && o) this.projectsSelectedCached.splice(0) },

    /**
     * Open the download dialog for a question
     * @param  {Object} project A project object
     */
    openDownload (project) {
      this.download.project = project.id
      this.download.creditsOpen = _.sumBy(project.questions, 'credits_open')
      this.download.nanswers = project.nanswers
      this.download.active = true
    },

    openDownloadV2 (project) {
      this.download.project = project.id
      this.download.creditsOpen = _.sumBy(project.questions, 'credits_open')
      this.download.nanswers = project.nanswers
      this.download.activeV2 = true
    },

    /**
     * Handler which is called when a project is downloaded
     * Sets the open credits to zero locally for all project questions
     */
    projectDownloaded () {
      let project = _.find(this.projects, { id: this.download.project })
      project.questions.forEach(q => { q.credits_open = 0 })
    },

    /**
     * Open the permissions editor
     * @param  {Object} project The project for which to change the permissions
     */
    openPermissions (project) {
      this.permissionEditor.projectID = project.id
      this.permissionEditor.projectName = project.name
      this.permissionEditor.projectOwnerID = project.owner_id
      this.permissionEditor.active = true
    },

    /**
     * Handler when ownership of project (currently open in permissions editor) has changed
     * @param  {Object} newOwner The new owner of the project
     */
    changeOwnership (newOwner) {
      let project = _.find(this.projects, { id: this.permissionEditor.projectID })
      project.owner = newOwner.name
      project.owner_id = newOwner.id
      this.permissionEditor.projectOwnerID = newOwner.id
    },

    /**
     * Archive the selected projects
     */
    archiveProjects (projectsSelected) {
      this.openEditProjects(projectsSelected, false)
      this.editProject.completed = true
      this.closeEditProject(true, true, true)
    },

    /**
     * Create tag and apply it to selected projects
     */
    createTag (projectsSelected) {
      this.tagsSelected.push(this.tagFilterSearch)
      this.tagProjects(projectsSelected, true)
    },

    /**
     * Tag the selected projects
     */
    tagProjects (projectsSelected, refetchConfig = false) {
      this.openEditProjects(projectsSelected, false)
      this.editProject.labelsChanged = true
      this.editProject.labels = this.tagsSelected
      this.closeEditProject(true, refetchConfig)
      this.editProject.labelsChanged = false
    },

    openEditProjects (projectsSelected, openModal = true) {
      // function which checks if the specified property is equal for all selected projects
      let allElementsEqual = property => _.map(projectsSelected, property).every((val, i, arr) => val === arr[0])

      this.editProject.name = this.editProject.originalName = _.map(projectsSelected, 'name').join(', ')
      this.editProject.completedIndeterminate = !allElementsEqual('completed')
      this.editProject.completed = this.editProject.completedIndeterminate ? false : projectsSelected[0].completed
      this.editProject.data_source = projectsSelected.length > 1 ? null : projectsSelected[0].data_source
      this.editProject.nameInvalid = ''

      if (openModal) this.editProject.active = true

      this.projectsSelectedCached = projectsSelected.slice()
    },

    openEditQuestion (question) {
      this.editQuestion.question = question
      this.editQuestion.name = this.editQuestion.originalName = question.name
      this.editQuestion.description = question.description
      this.editQuestion.completed = question.completed
      this.editQuestion.nameInvalid = ''
      this.editQuestion.active = true
    },

    closeEditProject (save, refetchConfig = false, refetchProjects = false) {
      if (save) {
        let propsToUpdate = {}
        let n = this.projectsSelectedCached.length

        if (n === 1) {
          if (this.editProject.name.length < MIN_PROJECT_NAME_LEN) {
            this.editProject.nameInvalid = this.$t('edit.name_invalid', { n: MIN_PROJECT_NAME_LEN })
            return
          } else propsToUpdate.name = this.editProject.name
        }

        // if (this.editProject.language !== '') propsToUpdate.language = this.editProject.language
        if (!this.editProject.completedIndeterminate) propsToUpdate.completed = this.editProject.completed
        if (this.editProject.labelsChanged) propsToUpdate.labels = this.editProject.labels
        if (this.editProject.data_source) { propsToUpdate.data_source = omitDeep(this.editProject.data_source, ['integration', 'items']) }

        let requests = []
        this.projectsSelectedCached.forEach(s => {
          _.keys(propsToUpdate).forEach(k => {
            // If the completed key was updated, we also need to update all projects
            // if (k === 'completed') s.questions.forEach(q => { q.completed = propsToUpdate[k] })
            s[k] = propsToUpdate[k]
          })

          requests.push(api.patch('/api/projects/' + s.id, propsToUpdate))
        })

        axios.all(requests)
          .then(() => {
            if (refetchConfig) {
              this.$refs.projectList.clearSelected()
              this.loadConfig()
                .then(() => {
                  if (refetchProjects) {
                    this.loadProjects()
                      .then(() => {
                        this.projectsLoading = false
                      })
                  }
                })
              this.tagFilterSearch = ''
            }
          })
          .then(() => {
            this.$root.snackMsg(this.$t('edit.done', {
              name: this.$escapeHtml(this.editProject.originalName), item: this.$tc('project.items', n)
            }))
          }, err => {
            this.$maybeRaiseAPIPromiseErr(err)
            this.$root.snackMsg(this.$t('error', { step: this.$t('save.title') }))
          })

        this.computeProjectProperties('projects')
      }
      this.editProject.active = false
    },

    closeEditQuestion (save) {
      if (save) {
        let propsToUpdate = {}

        if (this.editQuestion.name.length < MIN_QUESTION_NAME_LEN) {
          this.editQuestion.nameInvalid = this.$t('edit.name_invalid', { n: MIN_QUESTION_NAME_LEN })
          return
        }

        propsToUpdate.name = this.editQuestion.name
        propsToUpdate.description = this.editQuestion.description
        propsToUpdate.completed = this.editQuestion.completed

        _.keys(propsToUpdate).forEach(k => { this.editQuestion.question[k] = propsToUpdate[k] })

        api.patch('/api/questions/' + this.editQuestion.question.id, propsToUpdate).then(res => {
          this.$root.snackMsg(this.$t('edit.done', { name: this.$escapeHtml(this.editQuestion.originalName), item: this.$t('question.item') }))
        }).catch(err => this.$maybeRaiseAPIPromiseErr(err))
        this.computeProjectProperties('projects')
      }
      this.editQuestion.active = false
    },

    openDeleteProjects (projectsSelected) {
      this.deleteProject.label = _.map(projectsSelected, 'name').join(', ')
      this.deleteProject.active = true
      this.projectsSelectedCached = projectsSelected.slice()
    },

    openDeleteQuestion (question) {
      this.deleteQuestion.question = question
      this.deleteQuestion.label = question.name
      this.deleteQuestion.active = true
    },

    closeDeleteProjects (doIt) {
      if (doIt) {
        let delay = 0
        let localProjectsSelected = _.cloneDeep(this.projectsSelectedCached)
        let n = localProjectsSelected.length
        // If the question edit dialog is still open, give it a moment to close before deleting the project
        if (this.$refs.projectList.questions.active) {
          this.$refs.projectList.hideQuestions()
          delay = 900
        }
        setTimeout(() => {
          localProjectsSelected.forEach(s => {
            api.delete('/api/projects/' + s.id).then(response => {
              this.$root.snackMsg(this.$t('delete.done', { name: this.$escapeHtml(this.deleteProject.label),
                item: this.$tc('project.items', n) }
              ))
              this.$refs.projectList.clearSelected()
            }).catch(err => {
              this.$root.snackMsg(this.$t('error', { 'step': this.$t('delete.title') }))
              this.$maybeRaiseAPIPromiseErr(err)
            })
          })

          let IDsToDelete = new Set(_.map(localProjectsSelected, 'id'))
          _.remove(this.projects, s => IDsToDelete.has(s.id))
          // Hack to make reactive again
          this.projects = [...this.projects]
        }, delay)
      }
      this.deleteProject.active = false
    },

    closeDeleteQuestion (doIt) {
      if (doIt) {
        api.delete('/api/questions/' + this.deleteQuestion.question.id).then(response => {
          this.$root.snackMsg(this.$t('delete.done', { name: this.$escapeHtml(this.deleteQuestion.label), item: this.$t('question.item') }))
        }).catch(err => {
          this.$root.snackMsg(this.$t('error', { 'step': this.$t('delete.title') }))
          this.$maybeRaiseAPIPromiseErr(err)
        })
        this.$root.snackMsg(this.$t('delete.done', { name: this.$escapeHtml(this.deleteQuestion.label), item: this.$tu('question.item') }))
        let p = this.deleteQuestion.question.project_obj
        p.questions.splice(_.findIndex(p.questions, { id: this.deleteQuestion.question.id }), 1)
        this.computeProjectProperties('projects')
      }
      this.deleteQuestion.active = false
    },

    /**
     * Returns the normalized current route object
     */
    getNormalizedCurrentRouteQuery (query) {
      const nQuery = { ...query }

      if (query.limit) {
        nQuery.limit = _.toInteger(query.limit)
      }

      if (query.page) {
        nQuery.page = _.toInteger(query.page)
      }

      if (query.archived) {
        nQuery.archived = query.archived === 'true'
      }

      return nQuery
    },

    /**
     * Maybe update the query, given the current pagination and filter values
     */
    maybeUpdateQuery (refetch = true) {
      if (_.isEqual(this.urlQuery, this.getNormalizedCurrentRouteQuery(this.$router.currentRoute.query))) return
      this.routeUpdatedFromHere = true

      // let method = _.keys(this.$router.currentRoute.query).length ? 'replace' : 'push'
      let method = 'push'

      if (refetch) {
        this.$router[method]({
          name: `projects-manage`,
          query: this.urlQuery
        })

        this.loadProjects()
          .then(() => {
            this.projectsLoading = false
          })
      } else {
        this.$router[method]({
          name: `projects-manage${this.focusProject ? '-id' : ''}`,
          params: { id: this.focusProject },
          query: this.urlQuery
        })
      }
    },

    /**
     * Handler when the filters have changed. Do nothing but update the internal state.
     * @param  {Object} filters The current project filters
     */
    filtersHandler (filters) {
      this.filters = filters
    },

    /**
     * Handler when the rows per page has changed. Set global rowsPerPage to local storage.
     * @param  {Number} rowsPerPage
     */
    rowsPerPageHandler (rowsPerPage) {
      this.setLsGlobalSettings({ rowsPerPage })
    },

    /**
     * Get the v2 question identifiers (refs) for the given project
     * @param  {String} id [description]
     */
    getV2identifiers (id) {
      api.get(`/api/ui/projects/${id}/to-refs`).then((res) => {
        this.v2identifier.project_id = res.data.project_id
        this.$set(this.v2identifier, 'questions_to_ref', res.data.questions_to_ref)
      })
    }
  }
}

</script>

<i18n locale='en' src='@/i18n/en/pages/ProjectList.json' />
<i18n locale='de' src='@/i18n/de/pages/ProjectList.json' />